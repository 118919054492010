.app-container {
  color: #434343;
  overflow-x: hidden;
}
.app-container .primary-btn {
  cursor: pointer;
  font-family: "Gothic A1", sans-serif;
  background-color: #faff87;
  font-size: 14px;
  font-weight: 600;
  color: #434343;
  height: 55px;
  width: 168px;
  outline: none !important;
  border: none;
  border-radius: 6px;
  box-shadow: 0 6px 6px 1px rgba(225, 230, 121, 0.45);
}
.app-container .primary-btn:hover {
  background-color: #b6f0c5 !important;
}
.app-container .eco-title-logo {
  position: absolute;
}
.app-container .eco-title-logo .svg-elem-1 {
  stroke-dashoffset: 332.7946472168px;
  stroke-dasharray: 332.7946472168px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
  transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
}
.app-container .eco-title-logo.active .svg-elem-1 {
  stroke-dashoffset: 0;
  fill: rgb(116, 58, 236);
}
.app-container .eco-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 70px;
  line-height: 120%;
  position: relative;
  color: #1e2337;
}
.app-container .btn-create {
  width: 100%;
  max-width: 351px;
  height: 64px;
  background: #f7fc86;
  box-shadow: 0px 10px 30px rgba(247, 252, 134, 0.4);
  border-radius: 32px;
  font-size: 16px;
}
.app-container .eco-game-container {
  background-color: #ece0ff;
  padding-bottom: 120px;
  padding-top: 100px;
}
.app-container .eco-game-container .eco-title {
  margin-top: 196px;
}
.app-container .eco-game-container .eco-game-content {
  display: flex;
  justify-content: center;
  margin-top: 58px;
  gap: 109px;
}
.app-container .eco-game-container .eco-game-content .eco-game-left {
  display: flex;
  margin-top: -40px;
  flex-direction: column;
  max-width: 400px;
}
.app-container .eco-game-container .eco-game-content .eco-game-left img {
  width: 100%;
}
.app-container .eco-game-container .eco-game-content .eco-game-right {
  display: flex;
  flex-direction: column;
  gap: 38px;
  margin-top: 35px;
}
.app-container .eco-game-container .eco-game-content .eco-game-right .eco-game-image {
  flex: 1;
  max-width: 691px;
}
.app-container .eco-game-container .eco-game-content .eco-game-right .eco-game-image img {
  width: 100%;
}
.app-container .eco-game-mobile-container {
  background-color: #ece0ff;
  align-items: center;
  gap: 40px;
  padding: 70px 24px 28px;
}
@media only screen and (min-width: 768px) {
  .app-container .eco-game-mobile-container .eco-title {
    margin: 40px 0;
  }
}
.app-container .eco-game-mobile-container .eco-game-logo {
  width: 322px;
  position: relative;
  margin-bottom: 28px;
}
.app-container .eco-game-mobile-container .eco-game-logo img {
  width: 100%;
}
.app-container .eco-game-mobile-container .eco-game-logo .eco-title-logo {
  position: absolute;
  left: 150px;
  top: 68px;
}
.app-container .eco-game-mobile-container .eco-game-logo .eco-title-logo .svg-elem-1 {
  stroke-dashoffset: 439.8652648926px;
  stroke-dasharray: 439.8652648926px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
  transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
}
.app-container .eco-game-mobile-container .eco-game-logo .eco-title-logo.active .svg-elem-1 {
  stroke-dashoffset: 0;
  fill: rgb(249, 251, 179);
}
.app-container .eco-game-mobile-container .eco-game-logo {
  width: 322px;
}
.app-container .eco-game-mobile-container .eco-game-logo img {
  width: 100%;
}
.app-container .eco-game-mobile-container .eco-game-content {
  width: 322px;
}
.app-container .eco-game-mobile-container .eco-game-content img {
  width: 100%;
}
.app-container .eco-game-mobile-container .eco-game-mobile-img {
  max-width: 330px;
  width: 100%;
}
.app-container .eco-game-mobile-container .btn-create {
  max-width: 342px;
}
.app-container .eco-store-container {
  padding: 100px 40px 100px;
  padding-right: 40px;
  justify-content: center;
  background-color: #fbfdd4;
  position: relative;
  align-items: center;
  z-index: 1;
}
.app-container .eco-store-container img {
  width: 100%;
}
.app-container .eco-store-container .eco-store-col-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
}
.app-container .eco-store-container .eco-store-col-content .eco-title {
  margin-left: 30px;
}
.app-container .eco-store-container .eco-store-col-content .eco-store-bottom-content {
  display: flex;
  gap: 109px;
  align-items: center;
  justify-content: center;
}
.app-container .eco-store-container .eco-store-col-content .eco-store-bottom-content .eco-store-card {
  width: 370px;
  border-radius: 32px;
  background: #ffffff;
  padding: 29px 26px 25px 26px;
  box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
}
.app-container .eco-store-container .eco-store-col-content .eco-store-bottom-content .eco-store-card .eco-store-card-text {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #1e2337;
}
.app-container .eco-store-container .eco-store-col-content .eco-store-bottom-content .eco-store-card .eco-store-card-subtitle {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 24px;
  margin-bottom: 8px;
  /* identical to box height */
  text-align: center;
  color: #1e2337;
}
.app-container .eco-store-container .eco-store-col-content .eco-store-bottom-content .eco-store-card .btn-create {
  width: 259px;
  border-radius: 8px;
  box-shadow: 0px 8px 26px #eaee88;
  margin: 8px 0px 27px;
}
.app-container .eco-store-container .eco-store-col-content .eco-store-bottom-content .eco-store-content {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.app-container .eco-store-container .eco-store-col-content .eco-store-bottom-content .eco-store-content .eco-store-item {
  display: flex;
  gap: 27px;
}
.app-container .eco-store-container .eco-store-col-content .eco-store-bottom-content .eco-store-content .eco-store-item .eco-store-icon {
  width: 122px;
  height: 122px;
  border-radius: 20px;
}
.app-container .eco-store-container .eco-store-col-content .eco-store-bottom-content .eco-store-content .eco-store-item .eco-store-item-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 380px;
}
.app-container .eco-store-container .eco-store-col-content .eco-store-bottom-content .eco-store-content .eco-store-item .eco-store-item-content .eco-store-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  /* identical to box height, or 150% */
  color: #1e2337;
}
.app-container .eco-store-container .eco-store-col-content .eco-store-bottom-content .eco-store-content .eco-store-item .eco-store-item-content .eco-store-description {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  max-width: 340px;
  color: #1e2337;
}
.app-container .eco-store-container .eco-store-col-content .eco-store-bottom-content .eco-store-right {
  flex: 1;
  max-width: 402px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.app-container .eco-store-container .eco-store-col-content .eco-store-bottom-content .eco-store-right img {
  width: 100%;
}
.app-container .eco-store-mobile-container {
  padding: 70px 0px 75px;
  background-color: #fbfdd4;
  align-items: center;
  gap: 40px;
}
.app-container .eco-store-mobile-container .eco-store-card {
  width: 370px;
  border-radius: 32px;
  background: #ffffff;
  padding: 29px 26px 25px 26px;
  box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
}
.app-container .eco-store-mobile-container .eco-store-card .eco-store-logo {
  width: 296px;
  position: relative;
}
.app-container .eco-store-mobile-container .eco-store-card .eco-store-logo .eco-title-logo {
  left: 160px;
  top: 57px;
}
.app-container .eco-store-mobile-container .eco-store-card img {
  width: 100%;
}
.app-container .eco-store-mobile-container .eco-store-card .eco-store-card-text {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #1e2337;
}
.app-container .eco-store-mobile-container .eco-store-card .eco-store-card-subtitle {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  text-align: center;
  color: #1e2337;
}
.app-container .eco-store-mobile-container .eco-store-card .btn-create {
  width: 259px;
  border-radius: 8px;
  box-shadow: 0px 8px 26px #eaee88;
  margin: 8px 0px 27px;
}
.app-container .eco-store-mobile-container .eco-store-content {
  display: flex;
  flex-direction: column;
  margin-top: 26px;
  max-width: 306px;
  gap: 40px;
}
.app-container .eco-store-mobile-container .eco-store-content .eco-store-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  max-width: 380px;
}
.app-container .eco-store-mobile-container .eco-store-content .eco-store-item .eco-store-icon {
  width: 122px;
  height: 122px;
  border-radius: 20px;
}
.app-container .eco-store-mobile-container .eco-store-content .eco-store-item .eco-store-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #1e2337;
  margin: 19px 0 0;
}
.app-container .eco-store-mobile-container .eco-store-content .eco-store-item .eco-store-description {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  line-height: 22px;
  max-width: 340px;
  color: #1e2337;
}
.app-container .eco-store-mobile-container .eco-store-right {
  flex: 1;
  max-width: 330px;
  margin-top: 53px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 37px;
}
.app-container .eco-store-mobile-container .eco-store-right img {
  width: 100%;
}
.app-container .eco-btn-arrow {
  width: auto !important;
}
.app-container .introduction-container {
  position: relative;
  background-color: #d7fdff !important;
  display: flex;
  justify-content: center;
  gap: 99px;
  align-items: center;
  margin: auto;
  padding: 94px 0 95px;
  flex-direction: column;
  margin-bottom: -120px;
}
@media only screen and (min-width: 1000px) {
  .app-container .introduction-container {
    margin-bottom: 50px;
    padding: 130px 50px 200px;
  }
}
@media only screen and (min-width: 1280px) {
  .app-container .introduction-container {
    flex-direction: row;
    padding: 70px 50px 200px;
  }
}
.app-container .introduction-container .description {
  display: flex;
  flex-direction: column;
  padding: 50px 0% 0 0%;
  position: relative;
  z-index: 10;
  flex: 1;
  max-width: 614px;
  min-width: 576px;
  color: #1e2337;
  margin-bottom: 28px;
}
@media (min-width: 768px) {
  .app-container .introduction-container .description {
    margin-bottom: 100px;
  }
}
.app-container .introduction-container .description .eco-title-logo {
  top: 74px;
  left: 220px;
}
@media only screen and (min-width: 1280px) {
  .app-container .introduction-container .description .eco-title-logo {
    left: 438px;
  }
}
.app-container .introduction-container .description .eco-introduction-description {
  font-style: normal;
  font-size: 28px;
  line-height: 120%;
  margin-top: 32px;
  width: 100%;
  letter-spacing: 0.01em;
}
.app-container .introduction-container .description .eco-introduction-bottom {
  width: 100%;
}
.app-container .introduction-container .description .leaves-container-mobile {
  display: none;
}
.app-container .introduction-container .description .logo {
  width: 40px;
  margin-bottom: 40px;
}
.app-container .introduction-container .description .life-best {
  width: 300px;
}
@media screen and (min-width: 480px) {
  .app-container .introduction-container .description .life-best {
    display: none;
  }
}
.app-container .introduction-container .description .help-community {
  width: 550px;
}
@media screen and (max-width: 480px) {
  .app-container .introduction-container .description .help-community {
    display: none;
  }
}
.app-container .introduction-container .description .gifs-container-mobile {
  display: none;
}
.app-container .introduction-container .description .title {
  font-family: "Libre Baskerville";
  margin-bottom: 50px;
  font-size: 25px;
  line-height: 38px;
  font-weight: 700;
  width: 600px;
}
.app-container .introduction-container .description .sub-title {
  font-size: 20px;
  line-height: 28px;
  margin-top: 40px;
  margin-bottom: 20px;
  font-weight: bold;
  width: 550px;
}
.app-container .introduction-container .description .brand-logos {
  width: 600px;
}
.app-container .introduction-container .description .brand-logo-group {
  display: none;
}
.app-container .introduction-container .description button {
  cursor: pointer;
  display: block;
}
.app-container .introduction-container .eco-intro-social {
  display: flex;
  align-items: center;
  gap: 20px;
}
.app-container .introduction-container .eco-intro-social .eco-introduction-bottom {
  max-width: 351px;
}
@media only screen and (min-width: 1280px) {
  .app-container .introduction-container .eco-intro-social .eco-introduction-bottom {
    max-width: 313px;
  }
}
@media only screen and (min-width: 1410px) {
  .app-container .introduction-container .eco-intro-social .eco-introduction-bottom {
    max-width: 351px;
  }
}
.app-container .introduction-container .eco-intro-social p {
  margin-bottom: 0px;
}
.app-container .introduction-container .instruction-img-container {
  display: none;
  flex: 1;
  z-index: 10;
  position: relative;
}
@media only screen and (min-width: 1280px) {
  .app-container .introduction-container .instruction-img-container {
    display: flex;
    max-width: 600px;
  }
}
.app-container .introduction-container .instruction-img-container .header-image {
  width: 100%;
}
.app-container .introduction-container .instruction-img-container .ecosystem-tm {
  position: absolute;
  margin-top: 80px;
  margin-left: 150px;
  width: 400px;
  height: 62px;
}
.app-container .introduction-container .instruction-img-container .header {
  position: absolute;
  top: 53%;
  left: -20px;
  transform: translateY(-50%);
  height: 540px;
}
.app-container .introduction-container .instruction-img-container .grey-container {
  position: relative;
  margin-left: 120px;
  width: 635px;
  height: 817px;
  border-bottom-left-radius: 100px;
  background-color: #efefef;
}
.app-container .introduction-container .instruction-img-container .barclaycard {
  position: absolute;
  top: 50%;
  left: 320px;
  transform: translateY(-50%);
  height: 480px;
}
.app-container .introduction-container .instruction-img-container .introduction {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  height: 400px;
}
.app-container .introduction-container .instruction-img-container .leave-1 {
  width: 34px;
  position: absolute;
  top: 197px;
  left: 464px;
}
.app-container .introduction-container .instruction-img-container .leave-2 {
  position: absolute;
  left: 506px;
  width: 20px;
  top: 577px;
}
.app-container .introduction-container .instruction-img-container .leave-3 {
  position: absolute;
  top: 50%;
  left: 143px;
  width: 25px;
  transform: translateY(-50%);
}
.app-container .introduction-container .instruction-img-container .leave-4 {
  width: 26px;
  position: absolute;
  top: 105px;
  left: 348px;
}
.app-container .introduction-container .instruction-img-container .leave-5 {
  width: 44px;
  position: absolute;
  top: 87px;
  left: 201px;
}
.app-container .introduction-container .instruction-img-container .leave-6 {
  width: 38px;
  position: absolute;
  left: 466px;
  top: 390px;
}
.app-container .introduction-container .instruction-img-container .leave-7 {
  position: absolute;
  left: 428px;
  width: 45px;
  top: 677px;
}
.app-container .introduction-container .instruction-img-container .leave-8 {
  position: absolute;
  left: 208px;
  width: 41px;
  top: 714px;
}
.app-container .features-container {
  display: flex;
  margin-bottom: 120px;
}
.app-container .features-container .lettering-container {
  position: relative;
}
.app-container .features-container .lettering-container .lettering {
  width: 429px;
  height: 562px;
}
.app-container .features-container .lettering-container .lettering-mobile {
  display: none;
}
.app-container .features-container .lettering-container .leaves-group {
  position: absolute;
  width: 90px;
  right: 15px;
  bottom: 15px;
}
.app-container .features-container .feature-items-container {
  flex: 1;
  padding: 0 6%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.app-container .features-container .feature-items-container .title {
  font-family: "Libre Baskerville";
  margin-bottom: 50px;
  font-size: 36px;
  line-height: 46px;
  font-weight: 700;
  text-align: center;
  margin-top: 0;
  max-width: 500px;
}
.app-container .features-container .feature-items-container .sub-title {
  margin: 0 auto;
  max-width: 80%;
  font-size: 24px;
  line-height: 38px;
  font-weight: 600;
  text-align: center;
}
.app-container .features-container .feature-items-container .feature-items {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  max-width: 760px;
  width: 100%;
}
.app-container .features-container .feature-items-container .feature-items .row {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.app-container .features-container .feature-items-container .feature-items .row .item-container {
  display: flex;
  align-items: center;
}
.app-container .features-container .feature-items-container .feature-items .row .item-container .number {
  font-family: "Alata", sans-serif;
  margin: 0 0 0 0;
  color: #faff87;
  font-size: 48px;
  width: 70px;
}
.app-container .features-container .feature-items-container .feature-items .row .item-container .item-details {
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.app-container .features-container .feature-items-container .feature-items .row .item-container .item-details img {
  height: 58px;
  margin-bottom: 33px;
}
.app-container .features-container .feature-items-container .feature-items .row .item-container .item-details p {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
}
.app-container .features-container .feature-items-container .feature-items .row:first-child {
  margin-bottom: 80px;
}
.app-container .Div-Ecosystem {
  position: relative;
  gap: 30px;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  margin-top: -200px;
  display: flex;
  flex-direction: column;
  z-index: 7;
  background: #fafafa;
}
.app-container .Div-Ecosystem .eco-boost-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
}
.app-container .Div-Ecosystem .eco-title {
  position: relative;
  color: #1e2337;
  text-align: center;
}
.app-container .Div-Ecosystem .eco-boost-cards-container {
  display: flex;
  position: relative;
  gap: 120px;
  align-items: center;
  justify-content: center;
}
.app-container .Div-Ecosystem .eco-boost-cards-container .eco-boost-card {
  width: 400px;
  height: 418px;
  padding-top: 40px;
  padding-left: 40px;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  gap: 16px;
  border-radius: 48px;
  background-color: white;
}
.app-container .Div-Ecosystem .eco-boost-cards-container .eco-boost-card.active {
  background-color: #f9fbb3;
}
.app-container .Div-Ecosystem .eco-boost-cards-container .eco-boost-card.active .eco-boost-card-icon {
  background-color: white;
}
.app-container .Div-Ecosystem .eco-boost-cards-container .eco-boost-card:hover {
  background-color: #f9fbb3;
}
.app-container .Div-Ecosystem .eco-boost-cards-container .eco-boost-card:hover .eco-boost-card-icon {
  background-color: white;
}
.app-container .Div-Ecosystem .eco-boost-cards-container .eco-boost-card .eco-boost-card-icon {
  width: 88px;
  height: 88px;
  background-color: #9fffff;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.app-container .Div-Ecosystem .eco-boost-cards-container .eco-boost-card .eco-boost-card-icon img {
  width: 48px;
}
.app-container .Div-Ecosystem .eco-boost-cards-container .eco-boost-card .eco-boost-card-title {
  margin-top: 8px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 100%;
  /* identical to box height, or 28px */
  color: #1e2337;
}
.app-container .Div-Ecosystem .eco-boost-cards-container .eco-boost-card .eco-boost-card-description {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  /* or 144% */
  color: #1e2337;
  opacity: 0.8;
}
.app-container .eco-boost-mobile-container {
  align-items: center;
  background-color: #d7fdff;
  padding: 100px 0;
}
@media only screen and (min-width: 768px) {
  .app-container .eco-boost-mobile-container {
    overflow: hidden;
    padding: 20px 0 100px;
  }
}
.app-container .eco-boost-mobile-container .C-Eco-Full-Content {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.app-container .eco-boost-mobile-container .C-Eco-Full-Content .eco-boost-card {
  width: 358px;
  padding: 40px 0px 40px 40px;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  gap: 16px;
  border-radius: 48px;
  background-color: white;
}
.app-container .eco-boost-mobile-container .C-Eco-Full-Content .eco-boost-card.active {
  background-color: #f9fbb3;
}
.app-container .eco-boost-mobile-container .C-Eco-Full-Content .eco-boost-card.active .eco-boost-card-icon {
  background-color: white;
}
.app-container .eco-boost-mobile-container .C-Eco-Full-Content .eco-boost-card .eco-boost-card-icon {
  width: 88px;
  height: 88px;
  background-color: #9fffff;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.app-container .eco-boost-mobile-container .C-Eco-Full-Content .eco-boost-card .eco-boost-card-icon img {
  width: 48px;
}
.app-container .eco-boost-mobile-container .C-Eco-Full-Content .eco-boost-card .eco-boost-card-title {
  margin-top: 8px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 100%;
  /* identical to box height, or 28px */
  color: #1e2337;
}
.app-container .eco-boost-mobile-container .C-Eco-Full-Content .eco-boost-card .eco-boost-card-description {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  /* or 144% */
  color: #1e2337;
  opacity: 0.8;
}
.app-container .eco-boost-mobile-container .C-Eco-Full-Content .eco-boost-mobile {
  width: 400px;
}
.app-container .eco-referral-container {
  position: relative;
  z-index: 1;
}
.app-container .C-EcoChat {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  z-index: -1;
  background-color: #e9ffe3;
  color: #0f1b36;
  margin-bottom: -185px;
}
.app-container .C-EcoChat .eco-chat-container {
  display: flex;
  justify-content: center;
  padding-bottom: 186px;
}
@media only screen and (min-width: 1000px) {
  .app-container .C-EcoChat .eco-chat-container {
    padding-bottom: 236px;
    margin-top: 48px;
  }
}
@media only screen and (min-width: 1280px) {
  .app-container .C-EcoChat .eco-chat-container {
    gap: 111px;
    padding-bottom: 186px;
    margin-top: 0;
  }
}
.app-container .C-EcoChat .eco-chat-container .eco-chat-left {
  max-width: 594px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  display: none;
}
@media only screen and (min-width: 1080px) {
  .app-container .C-EcoChat .eco-chat-container .eco-chat-left {
    max-width: none;
    justify-content: flex-start;
    height: 720px;
    width: 631px;
    display: flex;
  }
}
.app-container .C-EcoChat .eco-chat-container .eco-chat-left img {
  width: 100%;
}
.app-container .C-EcoChat .eco-chat-container .eco-chat-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 60px;
}
.app-container .C-EcoChat .eco-chat-container .eco-chat-right .eco-chat-item {
  display: flex;
  gap: 27px;
}
.app-container .C-EcoChat .eco-chat-container .eco-chat-right .eco-chat-item .eco-chat-image {
  width: 120px;
  height: 120px;
}
.app-container .C-EcoChat .eco-chat-container .eco-chat-right .eco-chat-item .eco-chat-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.app-container .C-EcoChat .eco-chat-container .eco-chat-right .eco-chat-item .eco-chat-content .eco-chat-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height, or 150% */
}
.app-container .C-EcoChat .eco-chat-container .eco-chat-right .eco-chat-item .eco-chat-content .eco-chat-description {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}
.app-container .eco-pay-container {
  display: flex;
  gap: 7px;
  justify-content: center;
  align-items: center;
  background-color: #0f1b36;
  padding: 148px 0px 200px;
  flex-direction: row-reverse;
  gap: 80px;
}
.app-container .eco-pay-container .eco-pay-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 370px;
  position: relative;
  height: 100%;
}
@media only screen and (min-width: 1000px) {
  .app-container .eco-pay-container .eco-pay-left {
    display: none;
  }
}
@media only screen and (min-width: 1280px) {
  .app-container .eco-pay-container .eco-pay-left {
    display: flex;
  }
}
.app-container .eco-pay-container .eco-pay-left img {
  width: 100%;
  z-index: 10;
}
.app-container .eco-pay-container .eco-pay-content {
  display: flex;
  flex-direction: column;
  gap: 63px;
}
.app-container .eco-pay-container .eco-pay-content .eco-pay-top {
  display: flex;
  justify-content: space-between;
}
.app-container .eco-pay-container .eco-pay-content .eco-pay-top .eco-pay-top-left {
  display: flex;
  flex-direction: column;
  gap: 35px;
}
.app-container .eco-pay-container .eco-pay-content .eco-pay-top .eco-pay-top-left .eco-pay-logo {
  width: 274px;
  position: relative;
}
.app-container .eco-pay-container .eco-pay-content .eco-pay-top .eco-pay-top-left .eco-pay-logo img {
  width: 100%;
}
.app-container .eco-pay-container .eco-pay-content .eco-pay-top .eco-pay-top-left .eco-pay-logo .eco-pay-title-logo {
  position: absolute;
  left: 140px;
  top: 70px;
}
.app-container .eco-pay-container .eco-pay-content .eco-pay-top .eco-pay-top-left .eco-pay-logo .eco-pay-title-logo .svg-elem-1 {
  stroke-dashoffset: 353.9279785156px;
  stroke-dasharray: 353.9279785156px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
  transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
}
.app-container .eco-pay-container .eco-pay-content .eco-pay-top .eco-pay-top-left .eco-pay-logo .eco-pay-title-logo.active .svg-elem-1 {
  stroke-dashoffset: 0;
  fill: rgb(0, 255, 255);
}
.app-container .eco-pay-container .eco-pay-content .eco-pay-top .eco-pay-top-left .eco-pay-description {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  /* or 150% */
  color: #1e2337;
}
.app-container .eco-pay-container .eco-pay-content .eco-pay-top .eco-pay-top-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
}
.app-container .eco-pay-container .eco-pay-content .eco-pay-top .eco-pay-top-right .eco-pay-territory {
  width: 274px;
  height: 74px;
  display: flex;
  user-select: none;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 16px;
  gap: 16px;
}
.app-container .eco-pay-container .eco-pay-content .eco-pay-top .eco-pay-top-right .eco-pay-territory img {
  width: 52px;
  height: 40px;
  border-radius: 5px;
}
.app-container .eco-pay-container .eco-pay-content .eco-pay-top .eco-pay-top-right .eco-pay-territory p {
  margin-bottom: 0px;
  font-style: normal;
  font-size: 22px;
  line-height: 36px;
  color: #1e2337;
}
.app-container .eco-pay-container .eco-pay-content .eco-pay-grid-container {
  display: grid;
  grid-template-columns: repeat(7, auto);
  gap: 16px;
}
.app-container .eco-pay-container .eco-pay-content .eco-pay-grid-container .eco-pay-icon {
  width: 96px;
  height: 96px;
  display: flex;
  align-items: center;
  border-radius: 10px;
}
.app-container .eco-pay-container .eco-pay-content .eco-pay-grid-container .eco-pay-icon img {
  width: 100%;
  border-radius: 8px;
}
.app-container .eco-bank-container {
  padding: 231px 0px 219px;
  display: flex;
  flex-direction: row !important;
  justify-content: center;
  align-items: center;
  gap: 53px;
}
.app-container .eco-bank-container .eco-bank-content {
  display: flex;
  flex-direction: column;
  gap: 27px;
}
.app-container .eco-bank-container .eco-bank-content .eco-bank-logo {
  width: 380px;
  position: relative;
}
.app-container .eco-bank-container .eco-bank-content .eco-bank-logo img {
  width: 100%;
}
.app-container .eco-bank-container .eco-bank-content .eco-bank-logo .eco-title-logo {
  position: absolute;
  left: 152px;
  top: 60px;
}
.app-container .eco-bank-container .eco-bank-content .eco-bank-logo .eco-title-logo .svg-elem-1 {
  stroke-dashoffset: 585.3869018555px;
  stroke-dasharray: 585.3869018555px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
  transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
}
.app-container .eco-bank-container .eco-bank-content .eco-bank-logo .eco-title-logo.active .svg-elem-1 {
  stroke-dashoffset: 0;
  fill: rgb(116, 58, 236);
}
.app-container .eco-bank-container .eco-bank-content .eco-bank-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 120%;
  /* or 34px */
  color: #1e2337;
}
.app-container .eco-bank-container .eco-bank-content .eco-bank-description {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #1e2337;
}
.app-container .eco-bank-container .eco-bank-content .eco-bank-tasks {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.app-container .eco-bank-container .eco-bank-content .eco-bank-tasks .eco-bank-task {
  display: flex;
  gap: 10px;
}
.app-container .eco-bank-container .eco-bank-content .eco-bank-tasks .eco-bank-task img {
  width: 22px;
  height: 22px;
  margin-top: 3px;
}
.app-container .eco-bank-container .eco-bank-right {
  flex: 1;
  max-width: 763px;
}
.app-container .eco-bank-container .eco-bank-right img {
  width: 100%;
}
.app-container .eco-bank-mobile-container {
  padding: 47px 0px 66px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 53px;
}
.app-container .eco-bank-mobile-container .eco-bank-content {
  display: flex;
  flex-direction: column;
  margin: 0px 10px;
  gap: 27px;
}
.app-container .eco-bank-mobile-container .eco-bank-content .eco-bank-logo {
  width: 333px;
  position: relative;
}
.app-container .eco-bank-mobile-container .eco-bank-content .eco-bank-logo img {
  width: 100%;
}
.app-container .eco-bank-mobile-container .eco-bank-content .eco-bank-logo .eco-title-logo {
  position: absolute;
  left: 122px;
  top: 61px;
}
.app-container .eco-bank-mobile-container .eco-bank-content .eco-bank-logo .eco-title-logo .svg-elem-1 {
  stroke-dashoffset: 585.3869018555px;
  stroke-dasharray: 585.3869018555px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
  transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
}
.app-container .eco-bank-mobile-container .eco-bank-content .eco-bank-logo .eco-title-logo.active .svg-elem-1 {
  stroke-dashoffset: 0;
  fill: rgb(116, 58, 236);
}
.app-container .eco-bank-mobile-container .eco-bank-content .eco-bank-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 120%;
  /* or 34px */
  color: #1e2337;
  text-align: center;
}
.app-container .eco-bank-mobile-container .eco-bank-content .eco-bank-description {
  font-family: "Open Sans";
  font-style: normal;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #1e2337;
}
.app-container .eco-bank-mobile-container .eco-bank-content .eco-bank-tasks {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.app-container .eco-bank-mobile-container .eco-bank-content .eco-bank-tasks .eco-bank-task {
  display: flex;
  gap: 10px;
}
.app-container .eco-bank-mobile-container .eco-bank-content .eco-bank-tasks .eco-bank-task img {
  width: 22px;
  height: 22px;
  margin-top: 3px;
}
.app-container .eco-bank-mobile-container .eco-bank-content .eco-bank-tasks .eco-bank-task .eco-bank-description {
  text-align: left;
}
.app-container .eco-bank-mobile-container .eco-bank-right {
  flex: 1;
  max-width: 763px;
}
.app-container .eco-bank-mobile-container .eco-bank-right img {
  width: 100%;
}
.app-container .eco-pay-mobile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #0f1b36;
  padding: 70px 0px 154px;
}
.app-container .eco-pay-mobile-container .eco-pay-logo {
  width: 274px;
  position: relative;
}
.app-container .eco-pay-mobile-container .eco-pay-logo img {
  width: 100%;
}
.app-container .eco-pay-mobile-container .eco-pay-logo .eco-title-logo {
  left: 141px;
  top: 67px;
}
.app-container .eco-pay-mobile-container .eco-pay-logo .eco-title-logo .svg-elem-1 {
  stroke-dashoffset: 353.9279785156px;
  stroke-dasharray: 353.9279785156px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
  transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
}
.app-container .eco-pay-mobile-container .eco-pay-logo .eco-title-logo.active .svg-elem-1 {
  stroke-dashoffset: 0;
  fill: rgb(116, 58, 236);
}
.app-container .eco-pay-mobile-container .eco-pay-mobile-img {
  margin-bottom: 27px;
  width: 100%;
  max-width: 330px;
}
.app-container .eco-pay-mobile-container .eco-pay-description {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 36px;
  padding: 68px 16px;
  text-align: center;
  color: #fff;
}
@media only screen and (min-width: 350px) {
  .app-container .eco-pay-mobile-container .eco-pay-description {
    font-size: 24px;
  }
}
.app-container .eco-pay-mobile-container .eco-pay-buttons {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
  margin: 27px 0px;
}
.app-container .eco-pay-mobile-container .eco-pay-buttons .eco-pay-territory {
  width: 274px;
  height: 74px;
  display: flex;
  user-select: none;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 16px;
  gap: 16px;
}
.app-container .eco-pay-mobile-container .eco-pay-buttons .eco-pay-territory img {
  width: 40px;
  height: 40px;
  border-radius: 5px;
}
.app-container .eco-pay-mobile-container .eco-pay-buttons .eco-pay-territory p {
  margin-bottom: 0px;
  font-style: normal;
  font-size: 22px;
  line-height: 36px;
}
.app-container .eco-pay-mobile-container .eco-pay-grid-container {
  display: grid;
  grid-template-columns: repeat(4, auto);
  gap: 14px;
}
.app-container .eco-pay-mobile-container .eco-pay-grid-container .eco-pay-icon {
  width: 74px;
  height: 74px;
  display: flex;
  align-items: center;
  border-radius: 10px;
}
.app-container .eco-pay-mobile-container .eco-pay-grid-container .eco-pay-icon img {
  width: 100%;
  border-radius: 8px;
}
.app-container .C-EcoTokens {
  display: flex;
  position: relative;
}
.app-container .C-EcoTokens img {
  width: 100%;
}
.app-container .C-EcoTokens .ecotoken-first-img {
  position: absolute;
  top: 300px;
  left: 0px;
  width: 80%;
}
.app-container .C-EcoTokens .ecotoken-second-img {
  position: absolute;
  bottom: 380px;
  right: 0px;
  width: 80%;
}
.app-container .C-EcoEarn {
  display: flex;
}
.app-container .C-EcoEarn img {
  width: 100%;
}
.app-container .C-EcoPay {
  margin: 0;
  display: flex;
}
.app-container .C-EcoPay img {
  width: 100%;
}
.app-container .C-Eco-Full-Section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  position: relative;
}
@media (min-width: 768px) {
  .app-container .C-Eco-Full-Section {
    flex-direction: column;
    gap: 0;
  }
}
.app-container .C-Eco-Full-Section .eco-boost-bg {
  display: none;
  position: absolute;
  width: 100%;
  left: 0px;
  top: 0px;
}
@media only screen and (min-width: 1000px) {
  .app-container .C-Eco-Full-Section .eco-boost-bg {
    display: block;
  }
}
.app-container .C-Eco-Full-Section .eco-title {
  text-align: center;
  color: #1e2337;
}
.app-container .C-Eco-Full-Section .eco-social-image {
  width: 80%;
  margin-top: 161px;
  margin-left: auto;
  right: 0;
}
.app-container .C-Eco-Full-Section .eco-full-bg {
  position: absolute;
  width: 100%;
  left: 0px;
  top: 0px;
}
.app-container .C-Eco-Full-Section .C-Eco-Full-Content {
  position: relative;
}
.app-container .C-Eco-Full-Section .C-Eco-Full-Content .eco-title {
  width: 344px;
  text-align: center;
  margin: 100px auto 0px auto;
}
.app-container .C-Eco-Full-Section .C-Eco-Full-Content .eco-card-mobile {
  margin-top: 11px;
}
.app-container .C-Eco-Full-Section .C-Eco-Full-Content .eco-card-engage {
  margin-top: 50px;
}
.app-container .C-Eco-Full-Section .C-Eco-Full-Content .eco-card-expand {
  margin-top: -66px;
}
.app-container .C-Eco-Full-Section .eco-social-mobile {
  margin: 64px 32px 82px 24px;
  width: auto;
}
.app-container .eco-social-container {
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  margin: 100px 0px 68px;
}
.app-container .eco-social-container .eco-social-content {
  display: flex;
  flex-direction: column;
  gap: 60px;
}
.app-container .eco-social-container .eco-social-content .eco-social-logo {
  position: relative;
  width: 322px;
}
.app-container .eco-social-container .eco-social-content .eco-social-logo .eco-title-logo {
  left: 150px;
  top: 65px;
}
.app-container .eco-social-container .eco-social-content .eco-social-logo .eco-title-logo .svg-elem-1 {
  stroke-dashoffset: 455.7027282715px;
  stroke-dasharray: 455.7027282715px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
  transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
}
.app-container .eco-social-container .eco-social-content .eco-social-logo .eco-title-logo.active .svg-elem-1 {
  stroke-dashoffset: 0;
  fill: rgb(116, 58, 236);
}
.app-container .eco-social-container .eco-social-content .eco-social-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 240px);
  row-gap: 22px;
  column-gap: 20px;
}
.app-container .eco-social-container .eco-social-content .eco-social-grid-container .eco-social-item {
  display: flex;
  flex-direction: column;
  padding: 10px;
  user-select: none;
  cursor: pointer;
  outline-style: none;
  outline-width: 5px;
  gap: 8px;
}
.app-container .eco-social-container .eco-social-content .eco-social-grid-container .eco-social-item .eco-social-item-top {
  display: flex;
  gap: 20px;
  align-items: center;
}
.app-container .eco-social-container .eco-social-content .eco-social-grid-container .eco-social-item .eco-social-item-top .eco-social-icon {
  width: 62px;
  height: 62px;
}
.app-container .eco-social-container .eco-social-content .eco-social-grid-container .eco-social-item .eco-social-item-top .btn-try {
  width: 100px;
  height: 40px;
  background-color: white;
  font-weight: 400;
  box-shadow: 0 6px 6px 1px #dddddd;
}
.app-container .eco-social-container .eco-social-content .eco-social-grid-container .eco-social-item .eco-social-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 36px;
  /* identical to box height, or 164% */
  color: #1e2337;
  margin-top: 16px;
}
.app-container .eco-social-container .eco-social-content .eco-social-grid-container .eco-social-item .eco-social-description {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  /* or 22px */
  color: #1e2337;
  opacity: 0.8;
}
.app-container .eco-social-container .eco-social-content .eco-social-grid-container .eco-social-item:hover {
  outline-style: solid;
}
.app-container .eco-social-container .eco-social-content .eco-social-grid-container .eco-social-item.active {
  outline-style: solid;
}
.app-container .eco-social-container .eco-social-bg {
  flex: 1;
  max-width: 529px;
  flex-direction: column;
  display: flex;
  justify-content: flex-end;
}
.app-container .eco-social-container .eco-social-bg img {
  width: 100%;
}
.app-container .eco-social-mobile-container {
  flex-direction: column;
  gap: 20px;
  align-items: center;
  gap: 64px;
  justify-content: flex-end;
  margin: 70px 0px 48px;
}
.app-container .eco-social-mobile-container .eco-social-logo {
  position: relative;
  width: 322px;
}
.app-container .eco-social-mobile-container .eco-social-logo .eco-title-logo {
  left: 151px;
  top: 65px;
}
.app-container .eco-social-mobile-container .eco-social-logo .eco-title-logo .svg-elem-1 {
  stroke-dashoffset: 455.7027282715px;
  stroke-dasharray: 455.7027282715px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
  transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
}
.app-container .eco-social-mobile-container .eco-social-logo .eco-title-logo.active .svg-elem-1 {
  stroke-dashoffset: 0;
  fill: rgb(116, 58, 236);
}
.app-container .eco-social-mobile-container .eco-social-grid-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  row-gap: 22px;
  column-gap: 30px;
}
.app-container .eco-social-mobile-container .eco-social-grid-container .eco-social-item {
  display: flex;
  flex-direction: column;
  padding: 15px;
  margin: 0px 20px;
  user-select: none;
  cursor: pointer;
  outline-style: none;
  outline-width: 5px;
  gap: 8px;
}
@media only screen and (min-width: 768px) {
  .app-container .eco-social-mobile-container .eco-social-grid-container .eco-social-item {
    align-items: center;
  }
}
@media only screen and (min-width: 1000px) {
  .app-container .eco-social-mobile-container .eco-social-grid-container .eco-social-item {
    align-items: flex-start;
  }
}
.app-container .eco-social-mobile-container .eco-social-grid-container .eco-social-item .eco-social-item-top {
  display: flex;
  gap: 20px;
  align-items: center;
}
.app-container .eco-social-mobile-container .eco-social-grid-container .eco-social-item .eco-social-item-top .eco-social-icon {
  width: 88px;
  height: 88px;
}
.app-container .eco-social-mobile-container .eco-social-grid-container .eco-social-item .eco-social-item-top .btn-try {
  width: 100px;
  height: 40px;
  background-color: white;
  font-weight: 400;
  box-shadow: 0 6px 6px 1px #dddddd;
}
.app-container .eco-social-mobile-container .eco-social-grid-container .eco-social-item .eco-social-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 36px;
  /* identical to box height, or 164% */
  color: #1e2337;
  margin-top: 16px;
}
.app-container .eco-social-mobile-container .eco-social-grid-container .eco-social-item .eco-social-description {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */
  color: #1e2337;
  opacity: 0.8;
}
@media only screen and (min-width: 768px) {
  .app-container .eco-social-mobile-container .eco-social-grid-container .eco-social-item .eco-social-description {
    text-align: center;
  }
}
@media only screen and (min-width: 1000px) {
  .app-container .eco-social-mobile-container .eco-social-grid-container .eco-social-item .eco-social-description {
    text-align: left;
  }
}
.app-container .eco-social-mobile-container .eco-social-grid-container .eco-social-item:hover {
  outline-style: solid;
}
.app-container .eco-social-mobile-container .eco-social-grid-container .eco-social-item.active {
  outline-style: solid;
}
.app-container .eco-token-container {
  display: flex;
  flex-direction: column;
  gap: 220px;
  padding: 300px 0px 340px;
  position: relative;
  margin-top: -80px;
  z-index: 1;
}
.app-container .eco-token-container .eco-token-logo {
  width: 309px;
  position: relative;
}
.app-container .eco-token-container .eco-token-logo img {
  width: 100%;
}
.app-container .eco-token-container .eco-token-logo .eco-token-title-logo {
  position: absolute;
  left: 140px;
  top: 60px;
}
.app-container .eco-token-container .eco-token-logo .eco-token-title-logo .svg-elem-1 {
  stroke-dashoffset: 458.1123962402px;
  stroke-dasharray: 458.1123962402px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
  transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
}
.app-container .eco-token-container .eco-token-logo .eco-token-title-logo.active .svg-elem-1 {
  stroke-dashoffset: 0;
  fill: rgb(116, 58, 236);
}
.app-container .eco-token-container .eco-token-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  color: #faff87;
  line-height: 36px;
  /* identical to box height, or 150% */
  margin-bottom: 0;
  width: 700px;
}
.app-container .eco-token-container .eco-token-sub-title {
  font-weight: 400;
  font-size: 31px;
  line-height: 38px;
  margin: 22px 80px 0 0;
  color: #fff;
}
.app-container .eco-token-container .eco-token-description {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  /* or 29px */
  color: #ffffff;
}
.app-container .eco-token-container .eco-token-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -10;
  left: 0px;
  top: 0px;
}
.app-container .eco-token-container .eco-token-top {
  display: flex;
  align-items: center;
  gap: 50px;
}
.app-container .eco-token-container .eco-token-top .eco-token-top-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.app-container .eco-token-container .eco-token-top .eco-token-top-left img {
  height: 100%;
  max-height: 681px;
}
.app-container .eco-token-container .eco-token-top .eco-token-top-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 36px;
}
.app-container .eco-token-container .eco-token-top .eco-token-top-content .eco-token-top-image {
  width: 681px;
}
.app-container .eco-token-container .eco-token-bottom {
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 100px;
}
.app-container .eco-token-container .eco-token-bottom .eco-token-bottom-content {
  display: flex;
  flex-direction: column;
  gap: 36px;
}
.app-container .eco-token-container .eco-token-bottom .eco-token-bottom-content .eco-token-bottom-img {
  width: 661px;
}
.app-container .eco-token-container .eco-token-bottom .eco-token-bottom-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.app-container .eco-token-container .eco-token-bottom .eco-token-bottom-right img {
  max-height: 681px;
  height: 100%;
}
.app-container .eco-token-mobile-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 60px;
  padding: 0px 0px 360px;
  margin-top: -75px;
  z-index: 1;
  position: relative;
}
.app-container .eco-token-mobile-container .eco-token-logo {
  width: 309px;
  margin-bottom: 40px;
  position: relative;
}
.app-container .eco-token-mobile-container .eco-token-logo img {
  width: 100%;
}
.app-container .eco-token-mobile-container .eco-token-logo .eco-token-title-logo {
  position: absolute;
  left: 140px;
  top: 60px;
}
.app-container .eco-token-mobile-container .eco-token-logo .eco-token-title-logo .svg-elem-1 {
  stroke-dashoffset: 458.1123962402px;
  stroke-dasharray: 458.1123962402px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
  transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
}
.app-container .eco-token-mobile-container .eco-token-logo .eco-token-title-logo.active .svg-elem-1 {
  stroke-dashoffset: 0;
  fill: rgb(116, 58, 236);
}
.app-container .eco-token-mobile-container .eco-token-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 29px;
  line-height: 36px;
  text-align: center;
  color: #faff87;
  /* identical to box height, or 150% */
}
.app-container .eco-token-mobile-container .eco-token-sub-title {
  font-weight: 400;
  font-size: 23px;
  line-height: 38px;
  margin-top: 24px;
  color: #fff;
}
.app-container .eco-token-mobile-container .eco-token-description {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  text-align: center;
  /* or 29px */
  color: #ffffff;
}
.app-container .eco-token-mobile-container .eco-token-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -10;
  left: 0px;
  top: 0px;
}
.app-container .eco-token-mobile-container .eco-token-top-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px auto 0px 0px;
}
.app-container .eco-token-mobile-container .eco-token-top-left img {
  height: 100%;
  max-height: 681px;
}
.app-container .eco-token-mobile-container .eco-token-top-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 36px;
  padding-top: 285px;
}
.app-container .eco-token-mobile-container .eco-token-top-content .eco-token-top-image {
  margin-left: -25px;
  margin-bottom: -87px;
}
.app-container .eco-token-mobile-container .eco-token-bottom-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 36px;
  margin-top: 24px;
}
.app-container .eco-token-mobile-container .eco-token-bottom-content .eco-token-bottom-img {
  width: 385px;
}
.app-container .eco-token-mobile-container .eco-token-bottom-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0px 0px 0px auto;
  justify-content: center;
}
.app-container .eco-token-mobile-container .eco-token-bottom-right img {
  max-height: 681px;
  height: 100%;
}
.app-container .C-EcoEarn {
  display: flex;
}
.app-container .C-EcoEarn img {
  width: 100%;
}
.app-container .eco-id-container {
  display: flex;
  justify-content: center;
  background-color: #e9ffe3;
  align-items: center;
  gap: 75px;
  padding: 50px 0 100px;
}
.app-container .eco-id-container .eco-id-left {
  display: flex;
  flex-direction: column;
  gap: 60px;
}
.app-container .eco-id-container .eco-id-left .eco-id-top {
  display: flex;
  gap: 50px;
  align-items: center;
}
.app-container .eco-id-container .eco-id-left .eco-id-top .eco-id-logo {
  width: 227px;
  position: relative;
}
.app-container .eco-id-container .eco-id-left .eco-id-top .eco-id-logo img {
  width: 100%;
}
.app-container .eco-id-container .eco-id-left .eco-id-top .eco-id-logo .eco-title-logo {
  position: absolute;
  left: 155px;
  top: 82px;
}
.app-container .eco-id-container .eco-id-left .eco-id-top .eco-id-logo .eco-title-logo .svg-elem-1 {
  stroke-dashoffset: 199.8707885742px;
  stroke-dasharray: 199.8707885742px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
  transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
}
.app-container .eco-id-container .eco-id-left .eco-id-top .eco-id-logo .eco-title-logo.active .svg-elem-1 {
  stroke-dashoffset: 0;
  fill: rgb(116, 58, 236);
}
.app-container .eco-id-container .eco-id-left .eco-id-content {
  width: 699px;
}
.app-container .eco-id-container .eco-id-left .eco-id-content img {
  width: 100%;
}
.app-container .eco-id-container .eco-id-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 0 0;
  max-width: 388px;
}
.app-container .eco-id-container .eco-id-right img {
  width: 100%;
}
.app-container .C-EcoID {
  /* position: relative;
  margin-top: 100px; */
  display: flex;
  background-color: #f9fbb3;
  padding-bottom: 210px;
}
.app-container .C-EcoID img {
  width: 100%;
}
.app-container .testimonial {
  font-size: 22px;
  line-height: 38px;
  font-weight: 600;
  text-align: center;
  margin: 0 5% 40px 5%;
}
.app-container .community-live {
  background-image: linear-gradient(to right, #faff87, #b3ffff);
  padding: 40px 0;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.app-container .community-live .title {
  font-family: "Libre Baskerville", sans-serif;
  font-size: 30px;
  line-height: 46px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
  margin-bottom: 30px;
  padding: 0 20px;
}
.app-container .community-live-items {
  display: flex;
  flex-wrap: wrap;
  width: 810px;
}
.app-container .community-live-items-item {
  width: 250px;
  height: 180px;
  background: white;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  margin-right: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
  box-sizing: border-box;
  padding: 20px;
}
.app-container .community-live-items-item:nth-child(3n+0) {
  margin-right: 0;
}
.app-container .community-live-items-item .item-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  font-size: 24px;
}
.app-container .community-live-items-item .item-header img {
  height: 40px;
}
.app-container .community-live-items-item .item-content {
  text-align: center;
  margin-top: 30px;
  font-size: 15px;
}
.app-container .best-experience {
  position: relative;
  padding: 0 100px;
  margin-bottom: 100px;
}
.app-container .best-experience .best-experience-title-container {
  display: flex;
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
  .app-container .best-experience .best-experience-title-container {
    flex-direction: column;
  }
}
.app-container .best-experience .best-experience-title-container .title {
  font-family: "Libre Baskerville", sans-serif;
  font-size: 36px;
  line-height: 46px;
  font-weight: bold;
  margin-top: 0;
  padding: 0 20px;
  margin-bottom: 0;
  align-self: center;
}
.app-container .best-experience .best-experience-title-container img {
  height: 70px;
  object-fit: contain;
}
.app-container .best-experience .best-exp-content {
  display: flex;
  justify-content: space-between;
}
.app-container .best-experience .best-exp-content .retailers-tab-container {
  display: flex;
  align-items: center;
  width: 700px;
}
.app-container .best-experience .best-exp-content .retailers-tab-container .retailer-tab {
  flex: 1;
  margin-bottom: 30px;
  cursor: pointer;
  background: white;
  margin-right: 15px;
  padding: 20px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
.app-container .best-experience .best-exp-content .retailers-tab-container .retailer-tab.selected {
  background: #faff87;
}
.app-container .best-experience .best-exp-content .retailers-tab-container .retailer-tab img {
  width: 40px;
  margin-right: 15px;
}
.app-container .best-experience .best-exp-content .retailers-tab-container .retailer-tab:last-child {
  margin-right: 0;
}
.app-container .best-experience .best-exp-content .retailers-container {
  display: flex;
  flex-wrap: wrap;
  width: 700px;
  padding-left: 10px;
}
.app-container .best-experience .best-exp-content .retailers-container .retailer-item {
  width: 120px;
  height: 120px;
  margin: 0 20px 20px 0;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 16px;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}
.app-container .best-experience .best-exp-content .retailers-container .retailer-item:nth-child(5n+0) {
  margin-right: 10px;
}
.app-container .best-experience .best-exp-content .retailers-container .retailer-item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.app-container .best-experience .best-exp-content .ecopay-img {
  width: 330px;
  height: 660px;
}
.app-container .community {
  position: relative;
}
.app-container .community .title {
  font-family: "Beattingvile", sans-serif;
  font-size: 110px;
  margin-top: 0;
  margin-bottom: 30px;
  text-align: center;
}
.app-container .community .sub-title {
  font-family: "Libre Baskerville", sans-serif;
  font-size: 36px;
  line-height: 46px;
  font-weight: bold;
  text-align: center;
  margin-top: 0;
  margin-bottom: 30px;
}
.app-container .community .description {
  text-align: center;
  font-size: 24px;
  line-height: 38px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 40px;
  padding: 0 120px;
}
.app-container .community .leaves {
  width: 106px;
  position: absolute;
  right: 80px;
  top: 200px;
}
.app-container .community .member-container {
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
}
.app-container .community .member-container .item-container {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06), 0 6px 6px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  background: white;
  width: 277px;
  margin-right: 44px;
}
.app-container .community .member-container .item-container .image-container {
  width: 100%;
  position: relative;
}
.app-container .community .member-container .item-container .image-container img {
  width: 100%;
  height: 338px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.app-container .community .member-container .item-container .image-container .number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 158px;
  color: white;
  font-weight: 500;
  opacity: 0.6;
}
.app-container .community .member-container .item-container .member-title {
  font-size: 20px;
  line-height: 28px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #434343;
  text-align: center;
}
.app-container .community .member-container .item-container .member-desc {
  font-size: 14px;
  line-height: 24px;
  margin: 0 0 20px;
  color: #434343;
  text-align: center;
  padding: 0 35px;
}
.app-container .community .member-container .item-container:last-child {
  margin-right: 0;
}
.app-container .community .member-container .item-container:last-child .member-desc {
  padding: 0 30px;
}
.app-container .plus-member {
  position: relative;
  margin-bottom: 200px;
}
.app-container .plus-member .title {
  font-family: "Beattingvile", sans-serif;
  font-size: 110px;
  margin-top: 0;
  margin-bottom: 20px;
  text-align: center;
}
.app-container .plus-member .sub-title {
  font-family: "Libre Baskerville", sans-serif;
  font-size: 36px;
  line-height: 46px;
  font-weight: bold;
  text-align: center;
  margin-top: 0;
  margin-bottom: 30px;
}
.app-container .plus-member .description {
  margin: 0 auto 100px;
  max-width: 650px;
  text-align: center;
  font-size: 24px;
  line-height: 38px;
  font-weight: 600;
}
.app-container .plus-member .iphone {
  display: none;
}
.app-container .plus-member .leaves {
  width: 90px;
  position: absolute;
  top: 350px;
  left: calc(50% + 300px);
}
.app-container .plus-member .top-leaves {
  width: 90px;
  position: absolute;
  left: 15%;
  top: 0px;
}
.app-container .plus-member .content-container {
  display: flex;
}
.app-container .plus-member .content-container .content {
  flex: 1;
  position: relative;
}
.app-container .plus-member .content-container .content .grey-container {
  position: absolute;
  left: 0;
  right: 200px;
  top: 0;
  bottom: 0;
  background: #f4f8fb;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  z-index: -1;
}
.app-container .plus-member .content-container .content .items-container {
  margin: 120px 0 120px 100px;
}
@media (max-width: 1418px) {
  .app-container .plus-member .content-container .content .items-container {
    margin-left: 5%;
  }
}
.app-container .plus-member .content-container .content .items-container .row {
  display: flex;
  align-items: center;
  margin: 0 0 0 auto;
}
.app-container .plus-member .content-container .content .items-container .row:first-child {
  margin-bottom: 30px;
}
.app-container .plus-member .content-container .content .items-container .row .plus-item {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06), 0 6px 6px rgba(0, 0, 0, 0.06);
  padding: 30px 45px;
  border-radius: 6px;
  background: white;
  flex-shrink: 0;
  width: 186px;
  height: 193px;
  margin-right: 30px;
}
.app-container .plus-member .content-container .content .items-container .row .plus-item:last-child {
  margin-right: 0;
}
.app-container .plus-member .content-container .content .items-container .row .plus-item .title {
  margin-top: 0;
  margin-bottom: 0;
}
.app-container .plus-member .content-container .content .items-container .row .plus-item .sub-title {
  font-family: "Gothic A1", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  margin-top: 0;
  margin-bottom: 0;
}
.app-container .plus-member .content-container .content .items-container .row .plus-item .description {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
}
.app-container .plus-member .content-container .content .items-container .row .plus-item.savings .title {
  font-family: "MisterK", sans-serif;
  font-size: 64px;
  line-height: 64px;
}
.app-container .plus-member .content-container .content .items-container .row .plus-item.savings .sub-title {
  position: relative;
  top: -10px;
  margin-bottom: 15px;
}
.app-container .plus-member .content-container .content .items-container .row .plus-item.home .title {
  font-family: "SweetPea", sans-serif;
  font-size: 60px;
  line-height: 60px;
}
.app-container .plus-member .content-container .content .items-container .row .plus-item.home .sub-title {
  margin-bottom: 15px;
}
.app-container .plus-member .content-container .content .items-container .row .plus-item.travel .title {
  font-family: "Serendipity", sans-serif;
  font-size: 56px;
  line-height: 56px;
}
.app-container .plus-member .content-container .content .items-container .row .plus-item.travel .sub-title {
  margin-top: 4px;
  margin-bottom: 15px;
}
.app-container .plus-member .content-container .content .items-container .row .plus-item.food .title {
  font-family: "JennaSue", sans-serif;
  font-size: 55px;
  line-height: 55px;
}
.app-container .plus-member .content-container .content .items-container .row .plus-item.food .sub-title {
  margin-top: 5px;
  margin-bottom: 15px;
}
.app-container .plus-member .content-container .content .items-container .row .plus-item.entertainment .title {
  font-family: "MilkyWay", sans-serif;
  font-size: 50px;
  line-height: 50px;
}
.app-container .plus-member .content-container .content .items-container .row .plus-item.entertainment .sub-title {
  margin-top: 10px;
  margin-bottom: 15px;
}
.app-container .plus-member .content-container .content .items-container .row .plus-item.wellbeing .title {
  font-family: "TheSecretRegular", sans-serif;
  font-size: 52px;
  line-height: 52px;
}
.app-container .plus-member .content-container .content .items-container .row .plus-item.wellbeing .sub-title {
  margin-top: 8px;
  margin-bottom: 15px;
}
.app-container .plus-member .content-container .iphone-container {
  padding: 0 100px;
  display: flex;
  align-items: center;
}
.app-container .plus-member .content-container .iphone-container img {
  width: 266px;
}
@media (max-width: 1418px) {
  .app-container .plus-member .content-container .iphone-container {
    padding-left: 20px;
    padding-right: 5%;
  }
}
.app-container .interaction-container {
  display: flex;
  margin-bottom: 50px;
}
.app-container .interaction-container .details-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.app-container .interaction-container .details-container .container {
  max-width: 540px;
}
.app-container .interaction-container .details-container .container .title {
  font-size: 36px;
  line-height: 36px;
  font-family: "Libre Baskerville", sans-serif;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 30px;
}
.app-container .interaction-container .details-container .container .sub-title {
  font-size: 24px;
  line-height: 38px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 20px;
}
.app-container .interaction-container .details-container .container .description {
  font-size: 20px;
  line-height: 38px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 20px;
}
.app-container .interaction-container .details-container .container .description-mobile {
  font-size: 20px;
  line-height: 38px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 20px;
  display: none;
}
.app-container .interaction-container .details-container .container .ipad-mobile {
  display: none;
}
.app-container .interaction-container .details-container .container .comment-mobile {
  width: 100%;
  margin-bottom: 30px;
  display: none;
}
.app-container .interaction-container .details-container .container .comment {
  width: 100%;
  margin-bottom: 30px;
}
.app-container .interaction-container .img-container {
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 0 50px;
}
.app-container .interaction-container .img-container img {
  width: 100%;
  object-fit: contain;
}
@media (max-width: 1279px) {
  .app-container .eco-title {
    font-size: 29px;
    line-height: 46px;
  }
  .app-container .eco-title .eco-title-logo {
    position: absolute;
    bottom: -5px;
    width: 80px;
    left: 275px;
  }
  .app-container .introduction-container {
    position: relative;
  }
  .app-container .introduction-container .description {
    padding: 0 16px;
    margin-left: 0px;
    min-width: 0px;
    align-items: center;
  }
  .app-container .introduction-container .description .eco-title-logo {
    top: 40px;
    left: 225px;
  }
  .app-container .introduction-container .description .eco-introduction-description {
    font-size: 22px;
    max-width: 342px;
    text-align: center;
  }
  .app-container .introduction-container .description .btn-create {
    width: 100%;
    max-width: 342px;
    font-size: 14px;
  }
  .app-container .introduction-container .description .logo {
    width: 40px;
    display: block;
    margin: 20px auto;
  }
  .app-container .introduction-container .description .life-best {
    margin: 0 auto;
  }
  .app-container .introduction-container .description .help-community {
    margin: 0 auto;
  }
  .app-container .introduction-container .description .sub-title {
    width: unset;
    font-size: 20px;
    max-width: 380px;
    box-sizing: border-box;
    padding: 0 40px;
    text-align: center;
    margin: 40px auto 30px;
  }
  .app-container .introduction-container .description .gifs-container-mobile {
    display: block;
    height: 400px;
    width: 100%;
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .app-container .introduction-container .description .gifs-container-mobile .barclaycard {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-175px, -50%);
    width: 200px;
  }
  .app-container .introduction-container .description .gifs-container-mobile .introduction {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(10px, -50%);
    width: 160px;
  }
  .app-container .introduction-container .description .leaves-container-mobile {
    display: block;
  }
  .app-container .introduction-container .description .leaves-container-mobile .leave-1 {
    width: 20px;
    position: absolute;
    top: 160px;
    left: 220px;
  }
  .app-container .introduction-container .description .leaves-container-mobile .leave-2 {
    position: absolute;
    left: 506px;
    width: 20px;
    top: 577px;
  }
  .app-container .introduction-container .description .leaves-container-mobile .leave-3 {
    width: 11px;
    position: absolute;
    top: 95px;
    left: 60px;
  }
  .app-container .introduction-container .description .leaves-container-mobile .leave-4 {
    width: 11px;
    position: absolute;
    top: 145px;
    left: 150px;
  }
  .app-container .introduction-container .description .leaves-container-mobile .leave-5 {
    width: 25px;
    position: absolute;
    top: 95px;
    right: 60px;
    transform: rotate(40deg);
  }
  .app-container .introduction-container .description .leaves-container-mobile .leave-6 {
    width: 20px;
    position: absolute;
    left: 300px;
    top: 135px;
  }
  .app-container .introduction-container .description .leaves-container-mobile .leave-7 {
    position: absolute;
    left: 428px;
    width: 45px;
    top: 677px;
  }
  .app-container .introduction-container .description .leaves-container-mobile .leave-8 {
    position: absolute;
    left: 30px;
    width: 20px;
    top: 130px;
  }
  .app-container .introduction-container .description .title {
    padding: 0 0px;
    margin-top: 30px;
    width: 100%;
    text-align: center;
    margin-bottom: 0px;
    font-size: 20px;
  }
  .app-container .introduction-container .description .brand-logos {
    display: none;
    margin: 0 auto;
  }
  .app-container .introduction-container .description .brand-logo-group {
    display: block;
  }
  .app-container .introduction-container .description .brand-logo-group .brand-logo-row {
    padding: 0 20px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .app-container .introduction-container .description .brand-logo-group .brand-logo-row img {
    height: 25px;
    width: 100px;
    object-fit: contain;
    margin-right: 20px;
  }
  .app-container .introduction-container .description .brand-logo-group .brand-logo-row img:last-child {
    margin-right: 0;
  }
  .app-container .introduction-container .description .brand-logo-group .brand-logo-row:last-child {
    margin-bottom: 0;
  }
  .app-container .introduction-container .description button {
    margin: 0 auto;
  }
  .app-container .introduction-container .eco-intro-social {
    flex-direction: column;
  }
  .app-container .introduction-container .eco-intro-social p {
    font-family: "Poppins";
    font-weight: 500;
    font-size: 15px;
    line-height: 130%;
    /* or 19px */
    display: flex;
    align-items: center;
  }
  .app-container .introduction-container .instruction-img-container {
    width: 100%;
  }
  .app-container .introduction-container .instruction-img-container img {
    width: 100%;
  }
  .app-container .introduction-container .instruction-img-container .ecosystem-tm {
    width: 300px;
    margin: 0 auto;
    display: none;
  }
  .app-container .introduction-container .instruction-img-container .header {
    position: absolute;
    top: 53%;
    left: -20px;
    transform: translateY(-50%);
    height: 540px;
  }
  .app-container .eco-referrals-mobile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    margin-top: -137px;
    padding: 200px 0 80px;
  }
  .app-container .eco-referrals-mobile-container .eco-referrals-mobile-bg {
    position: absolute;
    z-index: -10;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
  }
  .app-container .eco-referrals-mobile-container .eco-referrals-logo {
    width: 100%;
    max-width: 341px;
    margin: 0px 0px 28px;
    position: relative;
  }
  .app-container .eco-referrals-mobile-container .eco-referrals-logo img {
    width: 100%;
  }
  .app-container .eco-referrals-mobile-container .eco-referrals-logo .eco-title-logo {
    position: absolute;
    left: 130px;
    top: 60px;
  }
  .app-container .eco-referrals-mobile-container .eco-referrals-logo .eco-title-logo .svg-elem-1 {
    stroke-dashoffset: 547.8596801758px;
    stroke-dasharray: 547.8596801758px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
  }
  .app-container .eco-referrals-mobile-container .eco-referrals-logo .eco-title-logo.active .svg-elem-1 {
    stroke-dashoffset: 0;
    fill: rgb(116, 58, 236);
  }
  .app-container .eco-referrals-mobile-container .eco-double-mobile-image {
    width: 381px;
    margin-left: 50px;
  }
  .app-container .eco-referrals-mobile-container .eco-title {
    color: #1e2337;
  }
  .app-container .eco-referrals-mobile-container .eco-title.eco-title-top {
    margin-bottom: 28px;
  }
  .app-container .eco-referrals-mobile-container .eco-title.eco-title-share {
    margin: 80px 16px 60px;
  }
  .app-container .eco-referrals-mobile-container .eco-referrals-content {
    display: flex;
    flex-direction: column;
    margin-top: 58px;
    gap: 40px;
  }
  .app-container .eco-referrals-mobile-container .eco-referrals-content .eco-referrals-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 306px;
    gap: 8px;
  }
  .app-container .eco-referrals-mobile-container .eco-referrals-content .eco-referrals-item .eco-referrals-icon {
    width: 122px;
    height: 122px;
    border-radius: 20px;
  }
  .app-container .eco-referrals-mobile-container .eco-referrals-content .eco-referrals-item .eco-referrals-title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height, or 150% */
    text-align: center;
    color: #1e2337;
    margin-top: 19px;
  }
  .app-container .eco-referrals-mobile-container .eco-referrals-content .eco-referrals-item .eco-referrals-description {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #1e2337;
  }
  .app-container .eco-referrals-mobile-container .eco-referrals-items {
    max-width: 391px;
    width: 100%;
    padding: 0 16px;
  }
  .app-container .C-Eco-Full-Section {
    flex-direction: column;
  }
  .app-container .C-Eco-Full-Section .eco-social-mobile {
    margin: 110px 32px 82px 24px;
  }
  .app-container .C-Eco-Full-Section.eco-chat-mobile-container {
    align-items: center;
    padding: 70px 0px 97px;
    background-color: #e9ffe3;
    color: #0f1b36;
    z-index: -1;
  }
}
@media (max-width: 1279px) and (min-width: 1120px) {
  .app-container .C-Eco-Full-Section.eco-chat-mobile-container {
    margin-bottom: 74px;
  }
}
@media (max-width: 1279px) {
  .app-container .C-Eco-Full-Section.eco-chat-mobile-container .eco-chat-logo {
    position: relative;
    width: 283px;
  }
  .app-container .C-Eco-Full-Section.eco-chat-mobile-container .eco-chat-logo .eco-title-logo {
    left: 155px;
    top: 60px;
  }
  .app-container .C-Eco-Full-Section.eco-chat-mobile-container img {
    width: 100%;
  }
  .app-container .C-Eco-Full-Section.eco-chat-mobile-container .eco-chat-heading {
    margin-bottom: 40px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 29px;
    line-height: 46px;
    /* identical to box height, or 125% */
    margin-bottom: 30px;
    text-align: center;
  }
  .app-container .C-Eco-Full-Section.eco-chat-mobile-container .eco-chat-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 55px;
  }
  .app-container .C-Eco-Full-Section.eco-chat-mobile-container .eco-chat-container .eco-chat-left {
    max-width: 337px;
    width: 100%;
  }
  .app-container .C-Eco-Full-Section.eco-chat-mobile-container .eco-chat-container .eco-chat-left img {
    width: 100%;
  }
  .app-container .C-Eco-Full-Section.eco-chat-mobile-container .eco-chat-container .eco-chat-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }
  .app-container .C-Eco-Full-Section.eco-chat-mobile-container .eco-chat-container .eco-chat-right .eco-chat-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 27px;
  }
  .app-container .C-Eco-Full-Section.eco-chat-mobile-container .eco-chat-container .eco-chat-right .eco-chat-item .eco-chat-image {
    width: 183px;
    height: 183px;
  }
  .app-container .C-Eco-Full-Section.eco-chat-mobile-container .eco-chat-container .eco-chat-right .eco-chat-item .eco-chat-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
  .app-container .C-Eco-Full-Section.eco-chat-mobile-container .eco-chat-container .eco-chat-right .eco-chat-item .eco-chat-content .eco-chat-title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height, or 150% */
  }
  .app-container .C-Eco-Full-Section.eco-chat-mobile-container .eco-chat-container .eco-chat-right .eco-chat-item .eco-chat-content .eco-chat-description {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
  }
  .app-container .C-Eco-Full-Section.eco-retailer-mobile-container {
    margin-bottom: 99px;
    gap: 44px;
    align-items: center;
  }
  .app-container .C-Eco-Full-Section.eco-retailer-mobile-container img {
    width: auto;
    height: 460px;
  }
  .app-container .C-Eco-Full-Section .eco-banking-mobile {
    margin: 47px 16px 13px 16px;
  }
  .app-container .C-Eco-Full-Section .eco-banking-mobile-second {
    margin: 0px 16px 66px 14px;
  }
  .app-container .C-Eco-Full-Section .eco-store-mobile {
    margin: 56px 10px 71px 10px;
  }
  .app-container .eco-free-mobile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    margin-top: 10px;
  }
  .app-container .eco-free-mobile-container .eco-free-mobile-top {
    width: 100%;
    max-width: 347px;
  }
  .app-container .eco-free-mobile-container .eco-free-mobile-top img {
    width: 100%;
  }
  .app-container .eco-free-mobile-container .eco-free-mobile-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
  .app-container .eco-free-mobile-container .eco-free-mobile-content .eco-free-title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 70px;
    /* identical to box height, or 146% */
    text-align: center;
    color: #1e2337;
  }
  .app-container .eco-free-mobile-container .eco-free-mobile-content .eco-free-description {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 36px;
    margin: 24px 0px 38px;
    /* or 180% */
    text-align: center;
    margin: 0px;
    max-width: 334px;
    color: #404040;
    opacity: 0.8;
  }
  .app-container .eco-free-mobile-container .eco-free-mobile-content .btn-create {
    margin-top: 16px;
    width: 328px;
  }
  .app-container .community-live-items {
    flex-direction: column;
    width: unset;
  }
  .app-container .community-live-items-item {
    margin-right: 0;
  }
  .app-container .best-experience {
    padding: 0;
  }
  .app-container .best-experience .best-exp-content {
    flex-direction: column;
  }
  .app-container .best-experience .best-exp-content .retailers {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .app-container .best-experience .best-exp-content .retailers .retailers-tab-container {
    flex-direction: column;
    width: unset;
    max-width: 400px;
    width: 100%;
  }
  .app-container .best-experience .best-exp-content .retailers .retailers-tab-container .retailer-tab {
    flex: unset;
    width: 90%;
    margin-bottom: 0;
    margin-right: 0;
  }
  .app-container .best-experience .best-exp-content .retailers .retailers-container {
    width: 280px;
    margin-top: 50px;
  }
  .app-container .best-experience .best-exp-content .retailers .retailers-container .retailer-item:nth-child(5n+0) {
    margin-right: 20px;
  }
  .app-container .best-experience .best-exp-content .retailers .retailers-container .retailer-item:nth-child(2n+0) {
    margin-right: 10px;
  }
  .app-container .best-experience .best-exp-content .ecopay-img {
    width: 240px;
    height: 480px;
    margin: 50px auto 0;
  }
  .app-container .best-experience .title {
    text-align: center;
  }
  .app-container .best-experience .retailers {
    flex-direction: column;
  }
  .app-container .features-container {
    display: block;
    margin-bottom: 0px;
  }
  .app-container .features-container .lettering-container .lettering {
    display: none;
  }
  .app-container .features-container .lettering-container .lettering-mobile {
    display: block;
    width: 100%;
  }
  .app-container .features-container .lettering-container .leaves-group {
    display: none;
  }
  .app-container .features-container .feature-items-container {
    position: relative;
    top: -130px;
  }
  .app-container .features-container .feature-items-container .title {
    display: none;
  }
  .app-container .features-container .feature-items-container .sub-title {
    height: 60px;
    position: relative;
    top: -125px;
  }
  .app-container .features-container .feature-items-container .feature-items {
    margin-top: 120px;
    display: block;
  }
  .app-container .features-container .feature-items-container .feature-items .row {
    display: block;
    margin-bottom: 0;
  }
  .app-container .features-container .feature-items-container .feature-items .row:first-child {
    margin-bottom: 0;
  }
  .app-container .features-container .feature-items-container .feature-items .row .item-container {
    margin: 0 auto 50px;
    display: flex;
    align-items: center;
    width: fit-content;
  }
  .app-container .features-container .feature-items-container .feature-items .row .item-container .number {
    font-family: "Alata", sans-serif;
    margin: 0;
    color: #faff87;
    font-size: 48px;
    width: 70px;
  }
  .app-container .features-container .feature-items-container .feature-items .row .item-container .item-details {
    width: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .app-container .features-container .feature-items-container .feature-items .row .item-container .item-details img {
    height: 58px;
    margin-bottom: 33px;
  }
  .app-container .features-container .feature-items-container .feature-items .row .item-container .item-details p {
    margin: 0;
    font-size: 14px;
    font-weight: bold;
  }
  .app-container .community .leaves {
    position: relative;
    top: 0;
    right: 0;
    display: block;
    margin: 0 auto;
  }
  .app-container .community .title {
    line-height: 80px;
  }
  .app-container .community .sub-title {
    font-family: "Libre Baskerville", sans-serif;
    font-size: 36px;
    line-height: 46px;
    font-weight: bold;
    text-align: center;
    margin-top: 0;
    margin-bottom: 30px;
  }
  .app-container .community .description {
    padding: 0 20px;
  }
  .app-container .community .member-container {
    flex-direction: column;
    justify-content: unset;
    align-items: center;
    margin-bottom: 70px;
  }
  .app-container .community .member-container .item-container {
    margin-right: 0;
    margin-bottom: 30px;
  }
  .app-container .plus-member {
    position: relative;
    margin-bottom: 100px;
    padding: 100px 0;
    background: #f4f8fb;
  }
  .app-container .plus-member .title {
    padding: 0 20px;
    line-height: 80px;
  }
  .app-container .plus-member .sub-title {
    padding: 0 30px;
  }
  .app-container .plus-member .description {
    padding: 0 30px;
    margin-bottom: 30px;
  }
  .app-container .plus-member .leaves {
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .app-container .plus-member .iphone {
    display: block;
    margin: 0 auto 30px;
    width: 250px;
    height: 460px;
  }
  .app-container .plus-member .top-leaves {
    display: none;
  }
  .app-container .plus-member .content-container .content .grey-container {
    display: none;
  }
  .app-container .plus-member .content-container .content .items-container {
    margin: 0;
  }
  .app-container .plus-member .content-container .content .items-container .row {
    display: flex;
    flex-direction: column;
  }
  .app-container .plus-member .content-container .content .items-container .row .plus-item {
    margin-right: 0;
    margin-bottom: 30px;
  }
  .app-container .plus-member .content-container .content .items-container .row .plus-item:last-child {
    margin-bottom: 0;
  }
  .app-container .plus-member .content-container .content .items-container .row .plus-item .title {
    padding: 0;
  }
  .app-container .plus-member .content-container .content .items-container .row .plus-item .sub-title {
    padding: 0;
  }
  .app-container .plus-member .content-container .content .items-container .row .plus-item .description {
    padding: 0;
  }
  .app-container .plus-member .content-container .iphone-container {
    display: none;
  }
  .app-container .interaction-container {
    margin-bottom: 50px;
    margin-top: -50px;
  }
  .app-container .interaction-container .img-container.desktop {
    display: none;
  }
  .app-container .interaction-container .details-container .container .title {
    text-align: center;
    display: none;
  }
  .app-container .interaction-container .details-container .container .sub-title {
    text-align: center;
    padding: 0 20px;
  }
  .app-container .interaction-container .details-container .container .description {
    text-align: center;
    padding: 0 30px;
    display: none;
  }
  .app-container .interaction-container .details-container .container .ipad-mobile {
    display: block;
    margin: 0 auto;
    width: 100%;
    margin-bottom: 30px;
  }
  .app-container .interaction-container .details-container .container .comment {
    width: 300px;
    margin: 0 auto 30px;
    display: none;
  }
  .app-container .interaction-container .details-container .container .comment-mobile {
    width: 300px;
    margin: 0 auto 30px;
    display: block;
  }
  .app-container .interaction-container .details-container .container .description-mobile {
    text-align: center;
    padding: 0 30px;
    display: block;
  }
  .app-container .interaction-container .details-container .container button {
    display: block;
    margin: 0 auto;
  }
}

.container {
  max-width: 100% !important;
}

.title-help-your-community {
  font-family: "Beattingvile", sans-serif;
  line-height: 55px;
}

.title {
  font-family: "Libre Baskerville";
  line-height: 46px;
  font-weight: 700;
}

.p-offer-your-own {
  line-height: 38px;
  font-weight: 600;
}

@media (max-width: 768px) {
  .p-offer-your-own {
    display: none;
  }
}
.eco-section__title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  color: #faff87;
  line-height: 36px;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .eco-section__title {
    font-size: 38px;
  }
}
.eco-section__sub-title {
  color: white;
  margin-top: 24px;
  font-size: 23px;
  font-weight: 400;
  line-height: 38px;
  margin-right: 40px;
  padding: 0 16px;
}
@media (min-width: 768px) {
  .eco-section__sub-title {
    font-size: 31px;
    padding: 0;
  }
}

.section-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 36px;
  text-align: center;
}

button:focus-visible {
  outline: none;
}
