@use "../../styles/partials/globals/mixins" as *;

#page-header {
  position: fixed !important;
  background-color: transparent;
  display: flex;
  align-items: center;
  z-index: 99;
  transition: all;
  transition-duration: 200ms;
  height: 86px;
  padding: 0 16px;

  @include tablet {
    padding: 0 32px;
  }

  @include desktop-sm {
    height: 100px;
  }

  &.scrolled-header {
    background-color: white;
    margin-top: 0px !important;
    .nav-main-link-name {
      color: #1e2337 !important;
      text-decoration: none;
    }
  }
}

.content-header {
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  margin: 0 !important;
  height: 4.375rem;
  padding: 0 !important;
  width: 100%;
  .heading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .eco-mode {
      cursor: pointer;
      display: none;

      @include desktop-sm {
        margin-bottom: 16px;
        display: block;
      }
    }

    .header-logo {
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-color: transparent;
      border: none;
      margin-bottom: 10px;
      cursor: pointer;
      margin-left: 0px;
      margin-right: 0px;
      display: none;

      @include desktop-sm {
        display: block;
        margin-left: 16px;
      }
    }

    .header-ecosystem {
      background-image: url("../../assets/new/ecosystem_tm_black.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-color: transparent;
      border: none;
      cursor: pointer;
      width: 220px;
      height: 60px;

      @include desktop-sm {
        display: none;
      }
    }

    @media (max-width: 999px) {
      .header-logo {
        display: none;
      }
    }

    .margin_img {
      display: none;
    }
    .profile {
      display: none;
    }
    button.nav-main-link,
    a.nav-main-link {
      font-size: 17px;
      border: none;
      background-color: transparent;
      &:hover {
        color: black;
        background-color: #faff87ff;
        span {
          color: black;
        }
      }
    }
    .nav-main-item {
      margin: 5px;
      position: relative;
      .nav-main-link {
        position: relative;
        .eco-title-logo {
          position: absolute;
          bottom: -10px;
          width: 80%;
          margin: auto;
          .svg-elem-1 {
            stroke-dashoffset: 332.7946472167969px;
            stroke-dasharray: 332.7946472167969px;
            fill: transparent;
            -webkit-transition: stroke-dashoffset 1s
                cubic-bezier(0.47, 0, 0.745, 0.715) 0s,
              fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
            transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715)
                0s,
              fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
          }

          &.active {
            .svg-elem-1 {
              stroke-dashoffset: 0;
              fill: rgb(116, 58, 236);
            }
          }
        }
      }
    }
    ul {
      z-index: 10000;
      .btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        p {
          width: 50px;
        }
      }
      a {
        color: black;
      }
      .nav-main-link {
        font-weight: 400;
        .nav-main-link-icon {
          color: black;
        }
      }
    }
  }
}

.nav-token-counter-wrapper {
  position: relative;
  display: inline-block;
  p {
    position: absolute;
    font-weight: 500;
    margin: auto;
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .tokens {
    font-size: 8px;
  }
}

@media (max-width: 1299px) {
  .content-header {
    ul {
      padding: 8px 0;
    }
    .heading a.nav-main-link {
      font-size: 15px;

      img {
        width: 10px !important;
      }
    }
  }
}

@media (max-width: 1160px) {
  .content-header {
    .heading {
      ul {
        .nav-main-item {
          margin-left: 0px;
          margin-right: 0px;
        }
      }
    }
  }
}

@media (max-width: 1090px) {
  .content-header {
    .heading {
      ul {
        .nav-main-item {
          .nav-main-link {
            padding-left: 0.25rem;
            padding-right: 0.25rem;
          }
        }
      }
    }
  }
}

@media (max-width: 1450px) {
  .content-header {
    .heading {
      ul {
        .nav-main-item {
          .nav-main-link {
            font-size: 14px;

            img {
              width: 10px !important;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .content-header {
    align-items: center;
    padding: 0 32px;

    ul {
      display: flex;
      flex-direction: row;
      margin-bottom: 0px;
      .logout {
        display: none !important;
      }
    }
  }
}

@media (max-width: 999px) {
  #page-header {
    .content-header {
      width: 100%;
      margin: 0px 24px 0px 24px;
      padding-right: 0px !important;
      align-items: center !important;
      .heading {
        width: 100%;
        margin-left: 0px;
        align-items: center;
        justify-content: space-between;

        .show {
          display: block;

          .nav-main-link {
            font-weight: 500;
            color: #495057;
            text-align: left;
            padding: 0 12px;
          }
          .nav-main-link-name {
            font-size: 12px;
          }
        }
        .hide {
          display: block;
          visibility: hidden;
        }
        .profile {
          display: block;
        }
        .nav-main-item {
          margin: 0px;
          margin-right: 0px;
        }
        .margin_img {
          margin-top: 18px;
          margin-left: auto;
          margin-bottom: auto;
        }
      }
      .second_menu {
        display: none;
      }
    }
  }
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.token_text {
  color: #152439;
  margin-bottom: 0px !important;
}

.token {
  width: 300px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  button {
    background: white;
    margin-left: auto;
    display: flex;
    align-items: center;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
  }
}

a.nav-main-link {
  cursor: pointer;
}

.burger {
  height: 46px;
  width: 46px;
  border: 1px solid #1e2337;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  background: transparent;

  @include desktop-sm {
    display: none;
  }

  &__line {
    height: 2px;
    width: 16px;
    background-color: #1e2337;
    border-radius: 4px;
  }
}

.header {
  &__list {
    position: absolute;
    top: 74px;
    right: 16px;
    background-color: white;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    gap: 1px;

    @include tablet {
      right: 32px;
    }

    @include desktop-sm {
      background-color: transparent;
      box-shadow: none;
      position: static;
      gap: 0;
    }
  }
}
