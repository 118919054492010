.eco-store-logo {
  width: 283px;
  position: relative;
}
.eco-store-logo img {
  width: 100%;
}
.eco-store-logo .eco-title-logo {
  left: 160px;
  top: 60px;
}
.eco-store__heading {
  color: #1e2337;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 80px;
  font-size: 29px;
  line-height: 46px;
  margin-top: 0;
  margin-bottom: 0;
}
@media only screen and (min-width: 768px) {
  .eco-store__heading {
    font-size: initial;
    line-height: initial;
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
@media only screen and (min-width: 1280px) {
  .eco-store__heading {
    margin-bottom: 0;
    margin-top: initial;
  }
}
.eco-store__heading .eco-store-logo {
  width: 296px;
  position: relative;
}
.eco-store__heading .eco-store-logo img {
  width: 100%;
}
.eco-store__heading .eco-store-logo .eco-title-logo {
  left: 160px;
  top: 57px;
}
