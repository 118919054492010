.eco-free {
  display: flex;
  justify-content: center;
  gap: 140px;
  padding: 70px 0 0;
}
.eco-free__img-container {
  height: 500px;
  width: auto;
}
.eco-free__bg-img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}
.eco-free__content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}
.eco-free__title {
  color: #1e2337;
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 70px;
  text-align: center;
}
.eco-free__description {
  color: #404040;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  margin: 24px 0 38px;
  opacity: 0.8;
}
