.eco-chat__heading-container {
  display: flex;
  align-items: center;
  gap: 80px;
}
@media only screen and (min-width: 1000px) {
  .eco-chat__heading-container {
    gap: 60px;
  }
}
@media only screen and (min-width: 1280px) {
  .eco-chat__heading-container {
    gap: 80px;
  }
}
.eco-chat__heading-container .eco-chat-logo {
  width: 283px;
  position: relative;
}
.eco-chat__heading-container .eco-chat-logo img {
  width: 100%;
}
.eco-chat__heading-container .eco-chat-logo .eco-title-logo {
  left: 160px;
  top: 60px;
}
@media only screen and (min-width: 768px) {
  .eco-chat-logo {
    margin-bottom: 50px;
  }
}
@media only screen and (min-width: 1000px) {
  .eco-chat-logo {
    margin-bottom: 0;
  }
}
.eco-chat__heading {
  margin: 0;
}
@media only screen and (min-width: 1000px) {
  .eco-chat__heading {
    font-size: 31px;
  }
}
@media only screen and (min-width: 1280px) {
  .eco-chat__heading {
    font-size: 38px;
  }
}

@media only screen and (min-width: 1000px) {
  .hide-on-desktop {
    display: none;
  }
}

.hide-on-mobile {
  display: none;
}
@media only screen and (min-width: 768px) {
  .hide-on-mobile {
    display: block;
  }
}
