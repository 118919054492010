.eco-social__heading-container {
  display: flex;
  align-items: center;
  gap: 80px;
}
.eco-social__heading {
  margin: 0;
}
.eco-social__container {
  display: flex;
  gap: 12px;
}
.eco-social--show-on-tablet {
  display: none;
}
@media only screen and (min-width: 768px) {
  .eco-social--show-on-tablet {
    display: block;
  }
}
@media only screen and (min-width: 1000px) {
  .eco-social--show-on-tablet {
    display: none;
  }
}
