.referrals {
  background-color: #cdfffb;
  padding: 48px 16px 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;
}
@media only screen and (min-width: 768px) {
  .referrals {
    padding: 48px 32px 64px;
  }
}
@media only screen and (min-width: 1280px) {
  .referrals {
    padding: 0 40px;
  }
}
.referrals__wave {
  height: 507px;
}
.referrals__top-wave {
  margin-top: -126px;
  margin-bottom: -340px;
}
@media only screen and (min-width: 1000px) {
  .referrals__top-wave {
    margin-top: -176px;
  }
}
@media only screen and (min-width: 1280px) {
  .referrals__top-wave {
    margin-top: -126px;
  }
}
.referrals__top-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin-bottom: 40px;
}
.referrals__logo-container {
  position: relative;
  display: flex;
  justify-content: center;
}
.referrals__heading {
  text-align: center;
  line-height: 36px;
  width: fit-content;
  margin-bottom: 0;
  font-size: 38px;
}
@media only screen and (min-width: 1000px) {
  .referrals__heading {
    font-size: 24px;
  }
}
@media only screen and (min-width: 1280px) {
  .referrals__heading {
    font-size: 38px;
  }
}
.referrals__logo {
  width: 322px;
  height: auto;
}
.referrals .eco-title-logo {
  right: 10px;
  bottom: -15px;
  position: absolute;
}
.referrals .eco-title-logo .svg-elem-1 {
  stroke-dashoffset: 585.3869018555px;
  stroke-dasharray: 585.3869018555px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
  transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s, fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
}
.referrals .eco-title-logo.active .svg-elem-1 {
  stroke-dashoffset: 0;
  fill: rgb(116, 58, 236);
}
.referrals__container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media only screen and (min-width: 1280px) {
  .referrals__container {
    flex-direction: row;
  }
}
.referrals__img {
  width: 550px;
  height: 100%;
  position: absolute;
  object-fit: contain;
  top: 46px;
}
.referrals__img-container {
  position: relative;
  height: 528px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media only screen and (min-width: 1280px) {
  .referrals__img-container {
    flex-basis: 50%;
  }
}
.referrals__sub-title {
  font-weight: 600;
  font-size: 22px;
  line-height: 38px;
  font-family: "inter";
}
@media only screen and (min-width: 1000px) {
  .referrals__sub-title {
    text-align: center;
  }
}
@media only screen and (min-width: 1280px) {
  .referrals__sub-title {
    margin-bottom: -22px;
  }
}
.referrals__content-container {
  display: flex;
  flex-direction: column;
  gap: 28px;
  width: 560px;
}
@media only screen and (min-width: 1280px) {
  .referrals__content-container {
    width: auto;
  }
}
.referrals__content {
  flex-basis: 67%;
}
.referrals__mobile-sub-title {
  font-family: "poppinsBold";
  text-align: center;
  color: #1e2337;
  font-size: 24px;
  margin: 24px 0;
}
@media only screen and (min-width: 768px) {
  .referrals__mobile-sub-title {
    margin: 64px 0;
  }
}
.referrals--show-on-desktop-sm {
  display: none;
}
@media only screen and (min-width: 1000px) {
  .referrals--show-on-desktop-sm {
    display: block;
  }
}
@media only screen and (min-width: 1280px) {
  .referrals--show-on-desktop-sm {
    display: none;
  }
}
@media only screen and (min-width: 1000px) {
  .referrals--hide-on-desktop-sm {
    display: none;
  }
}

.content-item {
  display: flex;
  gap: 28px;
}
.content-item__title {
  font-weight: 700;
  font-size: 20px;
  font-family: "inter";
  margin-bottom: 8px;
}
.content-item__text {
  width: 100%;
  margin-bottom: 0;
  font-family: "inter";
}
.content-item__img {
  width: 84px;
  height: 84px;
}
