.footer {
  background-color: #fbfdd4;
  padding: 64px 16px 32px;
}
@media (min-width: 768px) {
  .footer {
    padding: 100px 32px 24px;
  }
}
@media (min-width: 1200px) {
  .footer {
    padding: 100px 18vw 24px;
  }
}
.footer__top-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding-bottom: 42px;
  gap: 64px;
}
@media (min-width: 768px) {
  .footer__top-container {
    flex-direction: row;
    border-bottom: thin solid hsla(0, 0%, 59%, 0.5);
  }
}
.footer__bottom-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  gap: 60px;
}
@media (min-width: 768px) {
  .footer__bottom-container {
    flex-direction: row;
    gap: 0;
  }
}
.footer__logo {
  height: 38px;
  width: auto;
}
@media (min-width: 768px) {
  .footer__logo {
    height: 33px;
  }
}
.footer__link-container {
  display: flex;
  gap: 12px;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 768px) {
  .footer__link-container {
    gap: 80px;
    flex-direction: row;
  }
}
.footer__link {
  font-size: 16px;
  color: #434343;
  text-decoration: none;
}
@media (min-width: 768px) {
  .footer__link {
    font-size: 14px;
  }
}
.footer__text {
  font-size: 14px;
  margin: 0;
}
.footer i {
  cursor: pointer;
  font-size: 20px;
}
@media (min-width: 768px) {
  .footer i {
    font-size: 16px;
  }
}
